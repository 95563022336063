import React from "react"
import { css } from "@emotion/core"
import { FaSatellite, FaCircle, FaCircleNotch, FaBitcoin, FaDesktop, FaExclamation } from "react-icons/fa"
import Card from "./Card"
import TextItem from "./TextItem"
import Colors from "./colors"
import AirtableLink from "./AirtableLink"


const makeExpandedContent = (member) => {
  const tableId = "tblQK1NaSSjp8e5aH"
  const viewId = "viwdHbzPpUfw0yrt3"
  const recordId = member.Id
  return (
    <div css={css`
      margin-top: 1rem;
      display: flex;
    `}>
      <TextItem title="Email" value={member.Email} />
      <TextItem title="Cell #" value={member.Cell__  && member.Cell__.length === 10 ?`(${member.Cell__.slice(0,3)}) ${member.Cell__.slice(3,6)}-${member.Cell__.slice(6,10)}` : "no cell provided"} />
      <TextItem title="Membership" value={member.Membership ? member.Membership : "NO MEMBERSHIP IN SYSTEM"} />
      <AirtableLink tableId={tableId} viewId={viewId} recordId={recordId} />
    </div>
  )
}

const getMembershipIcon = (type, seat) => {
  const color = seat ? Colors.green[0] : Colors.red[0]
  switch (type) {
    case 'Alumni/Remote':
      return <FaSatellite title={'Membership: ' + type} color={color}/>
    case 'Embarc Collective':
      return <FaCircle title={'Membership: ' + type} color={color}/>
    case 'Open Collaboration':
      return <FaCircleNotch title={'Membership: ' + type} color={color}/>
    case 'Dedicated Desk':
      return <FaDesktop title={'Membership ' + type} color={color} />
    case 'BlockSpaces':
      return <FaBitcoin title={'Membership: ' + type} color={color}/>
    default:
      return <FaExclamation title={'Membership ' + type} color={color} />
  }
}

const Member = ({member, showCompany}) => {
  return (
    <Card condensed expandedChildren={makeExpandedContent(member)}>
      <div css={css`
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-right: 2rem;
      `}>
        <div>
          <div>{member.api_member_name}</div>
          {showCompany && <div style={{fontSize:'0.8rem'}}>{member.Company_Name_Lookup}</div>}
        </div>
        {getMembershipIcon(member.Membership, member.seat_name)}
      </div>
    </Card>
  )
}

export default Member