import React, {useState, useEffect, useContext} from "react"
import { graphql, Link } from "gatsby"
import { useAuth } from "gatsby-theme-firebase";
import Layout from "../components/layout";
import CompanyListItem from "../components/CompanyListItem"
import Session from "../components/Session"
import Milestone from "../components/Milestone"
import { useAirtableUser, useAirtableSessions }  from "../hooks/useAirtable"
import { GlobalStateContext, GlobalDispatchContext } from "../context/GlobalContextProvider"
import { css } from "@emotion/core"
import Colors from "../components/colors"
import Button from "../components/Button"
import Member from "../components/Member"
import Win from "../components/Win"

export default ({ data }) => {
  const companyId = data.airtable.data.Id;
  const { isLoading, isLoggedIn, profile } = useAuth();
  const [error, setError] = useState(null);
  const [fetchSessions, setFetchSessions] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [milestones, setMilestones] = useState([]);
  const [loadingMilestones, setLoadingMilestones] = useState(true);
  const [activeTab, setActiveTab] = useState('sessions'); //sessions, members, eventually license
  const userId = useAirtableUser();
  const companySessionMap = useAirtableSessions(null, fetchSessions)
  const [sessions, setSessions] = useState(null)
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)


  useEffect(() => {
    if(Object.keys(state.companySessionMap).length > 0 && state.companySessionMap[companyId]) { // this check might cause problems
      setFetchSessions(false)
    } else {
      setFetchSessions(true)
    }
  }, [])

  useEffect(() => {
    if(state.companySessionMap && state.companySessionMap[companyId]) {
      setSessions(state.companySessionMap[companyId])
      setFetchSessions(false)
    }
  }, [state])

  useEffect(() => {
    dispatch({type:'UPDATE_COMPANY_SESSION_MAP', payload: companySessionMap})
  }, [companySessionMap])

  const fetchActiveMilestones = async (id) => {
    return await fetch(
      `https://api.airtable.com/v0/${process.env.GATSBY_AIRTABLE_BASE_ID}/Milestones?maxRecords=6&view=API&filterByFormula=AND({Active}="1",{Company%20ID}="${id}")`,
      {
        method: 'get',
        headers: {'Authorization': `Bearer ${process.env.GATSBY_AIRTABLE_API_KEY}`}
      }
    )
  }

  // get milestones
  useEffect(() => {
    fetchActiveMilestones(companyId).then((result) => {
      if(result.status === 200) {
        result.json().then((body) => {
          if(body.records.length > 0) {
            setMilestones(body.records)
          }
          else {
            setMilestones({error:'No milestones found'})
          }
        })
      }
      else {
        setMilestones({error:'Error retrieving milestones'})
      }
      setLoadingMilestones(false)
    }).catch((err) => {
      console.log(err)
      setMilestones({error:'Error retrieving milestones'})
      setLoadingMilestones(false)
    })  
  }, [loadingMilestones])

  if (isLoading) {
    return <h1>Loading... {isLoading}</h1>;
  }
  else if (isLoggedIn && profile.emailVerified) {
    return (
      <Layout resetPadding>
        <div style={{
          padding:'2rem 3rem',
          height: '100%',
          width: '100%',
        }}>
          <div style={{
            maxWidth: 900,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}>
            <Link to="/companies">See all Companies</Link>
            <CompanyListItem company={data.airtable.data} sessions={sessions} headerMode/>
            <div style={{margin:'2rem 0', width:'100%',}}>
              Milestones
              <div style={{display:'flex', flexWrap:'wrap'}}>
                {
                  milestones.length > 0 && milestones.map(milestone => (
                    <Milestone key={milestone.id} milestone={milestone} width={250} />
                  ))
                }
              </div>
            </div>
            <div>
              <div css={css`
                display: flex;
                max-width: 600px;
              `}>
                <Button onClickFunction={() => setOpenForm(openForm === 'session' ? false : 'session' )}>{openForm === 'session' ? "Close Coaching Form" : "Add Coaching"}</Button>
                <Button onClickFunction={() => setOpenForm(openForm === 'win' ? false : 'win' )}>{openForm === 'win' ? "Close Win Form" : "Add Win"}</Button>
                <Button onClickFunction={() => setOpenForm(openForm === 'milestone' ? false : 'milestone' )}>{openForm === 'milestone' ? "Close Milestone Form" : "Add Milestone"}</Button>
              </div>
              <div style={{height: openForm === 'session' ? "auto" : 0, overflow: "hidden"}}>
                {openForm ==='session' && <iframe title="session" className="airtable-embed-form-session" src={`https://airtable.com/embed/shr5iB4KtGIhALBLl?prefill_Type=Coaching&prefill_Company=${companyId}&backgroundColor=teal&prefill_Team%20Member=${userId}`} frameBorder="0" width="100%" height="533" style={{background: 'transparent', border: '1px solid #ccc'}}></iframe>}
              </div>
              <div style={{height: openForm === 'win' ? "auto" : 0, overflow: "hidden"}}>
              {openForm ==='win' && <iframe title="win" className="airtable-embed-form-session" src={`https://airtable.com/embed/shrwwVPLvesaCDo31?prefill_Company=${companyId}&backgroundColor=teal&prefill_Team%20Member=${userId}`} frameBorder="0" width="100%" height="533" style={{background: 'transparent', border: '1px solid #ccc'}}></iframe>}
              </div>
              <div style={{height: openForm === 'milestone' ? "auto" : 0, overflow: "hidden"}}>
              {openForm ==='milestone' && <iframe title="milestone" className="airtable-embed-form-milestone" src={`https://airtable.com/embed/shrJcLL9jOcEVWer4?backgroundColor=teal&prefill_Company=${companyId}&prefill_Team%20Member=${userId}`} frameBorder="0" width="100%" height="533" style={{background: 'transparent', border: '1px solid #ccc'}}></iframe>}
              </div>
            </div>
          </div>
          <br />
        </div>
        <div style={{backgroundColor: Colors.gray[0], minHeight:700}}>
          <div>
          <button onClick={() => setActiveTab('sessions')}>{activeTab === 'sessions' ? "Sessions" : "View Sessions"}</button>
          <button onClick={() => setActiveTab('members')}>{activeTab === 'members' ? "Members" : "View Members"}</button>
          <button onClick={() => setActiveTab('wins')}>{activeTab === 'wins' ? "Wins" : "View Wins"}</button>
          </div>
          {activeTab === 'sessions' &&  
          <div style={{maxWidth: 960, padding:'2rem', marginLeft:'auto', marginRight:'auto',}}>
            <Button onClickFunction={() => setFetchSessions(!fetchSessions)}>
              {fetchSessions ? 'Loading' : 'Fetch sessions'}
            </Button>
            {sessions && sessions.map((session, i) => {
              session.Notes = session.Notes && session.Notes.internal ? session.Notes.internal.content : session.Notes
              // hide date if previous session has same date
              const hideDate = i > 0 ? (sessions[i-1]['Date Formula'] === session['Date Formula'] ? true : false) : false
              //session.Summary = session.Summary && session.Summary.internal ? session.Summary.internal.content : session.Summary
              return(
                <Session key={session.Id} session={session} hideDate={hideDate} condensed={true}/>
              )
            })}
            </div>
          }
          { activeTab === 'members' && 
            <div style={{maxWidth: 960, padding:'2rem', marginLeft:'auto', marginRight:'auto',}}>
              {data.memberQuery.edges.map(edge => 
                <Member member={edge.node.data} />
              )}
            </div>
          }
          { activeTab === 'wins' &&
            <div style={{maxWidth: 960, padding:'2rem', marginLeft:'auto', marginRight:'auto',}}>
              {data.winQuery.edges.map(edge => 
                <Win key={edge.node.id} win={edge.node.data} />
              )}
            </div>
          }
        </div>
      </Layout>
    )
  } else {
    return (
      <Layout>
        Please Log In
      </Layout>
    )
  }
  
}

export const query = graphql`
  query($id: String!) {
    airtable(table: {eq: "Companies"}, data: {Id: {eq:$id}}) {
      data {
        api_company_name
        Logo {
          localFiles {
            childImageSharp {
              fixed(width:50) {
                ...GatsbyImageSharpFixed
              }
            }
            extension
            url
          }
        }
        Active_Members
        Adjusted_Support_Hours
        AM_Name
        #_Coaching_Hours
        Description
        Id
      }
    }

    memberQuery: allAirtable(filter: {table: {eq: "Members"}, data: {company_id: {eq:$id}}}) {
      edges {
        node {
          data {
            Id
            Title
            Member_Name
            Email
            Cell__
            Membership
            Company_Name_Lookup
            seat_name
            api_member_name
          }
        }   
      }
    }

    winQuery: allAirtable(filter: {table: {eq: "Wins"}, data: {win_company_id: {eq:$id}}}, sort: {fields: data___Date, order: DESC}) {
      edges {
        node {
          data {
            Company
            Details
            Date
            Team_Member
          }
          id
        }
      }
    }

    allAirtable(filter: {table: {eq: "Engagements"}, data: {Company_ID: {eq: $id}}},sort: {fields: data___Date, order:DESC}) {
      edges {
        node {
          data {
            Id
            Title
            Date
            Type
            Member_Name
            Notes {
              internal {
                content
              }
            }
            Summary
            api_engagements_minutes
            Event
            Attended_
            speaker_name
            team_member_name
          }
        }
      }
    }
  }
`
