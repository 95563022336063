import React from "react"
import { css } from "@emotion/core"
import Colors from "./colors"

const Button = ({ onClickFunction, error, color, children }) => (
  <div style={{display:'flex', padding: '0.5rem 0.5rem', position: 'relative'}}>
      <button onClick={() => onClickFunction()}
        css={css`
          display: inline-block;
          cursor: pointer;
          text-decoration: none;
          outline: none;
          color: ${color ? color : Colors.black};
          position: relative;
          background-color: transparent;
          border: none;
        `}
      >
        <h3 css={css`
          margin: 0px;
          &::after {
            position: absolute;
            transform: translate(-50%, 0%);
            box-sizing: border-box;
            content: "";
            bottom: -4px;
            left: 50%;
            height: 3px;
            width: 90%;
            display: block;
            background-color: ${color ? color : Colors.black};
            transition: all .25s linear;
          }
          &:hover {
            &::after {
              width: 80%;
            }
          }
        `}>
          {children}
        </h3>
      </button>
      {error && <div style={{padding: '0.25rem 0 0 1rem'}}>{error}</div>}
    </div>
)

export default Button
