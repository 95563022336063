import React, {useState} from "react"
import { css } from "@emotion/core"
import Colors from "../components/colors"


const Card = ({condensed, children, expandedChildren}) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      position: 'relative',
    }}>
      <div
        label="click to expand"
        css={css`
          cursor: pointer;
          border-left: none;
          border-right: none;
          width: 80%;
          max-width: 900px;
          min-width: 300px;
          background-color: ${Colors.white};
          text-align: unset;
          border-top: 0.15px solid rgba(0, 0, 0, 0.5);
          border-bottom: 0.15px solid rgba(0, 0, 0, 0.5);
          transition: box-shadow 0.25s linear;
          &:hover {
            box-shadow: rgba(0, 0, 0, 0.2) -1px 3px 6px 0px;
            background-color: ${Colors.green[4]};
          }
        `}
        onClick={() => setExpanded(!expanded)}
      >
        <div style={{
          height: ((!expanded && condensed) || expanded) ? 'auto' : 100,
          overflow:'hidden',
          margin: '1rem',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}>
          <div style={{display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
            {children}
          </div>
          {
            ((condensed && expanded) || !condensed) &&
            expandedChildren
          }
        </div>
      </div>
    </div>
  )
}

export default Card