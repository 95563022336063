import React from "react"
import { css } from "@emotion/core"
import Card from "./Card"
import Colors from "./colors"



const Win = ({ win }) => {
  return (
    <Card condensed>
      <div css={css`
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-right: 2rem;
      `}>
        <div>
          <div>{win.Date + (win.Team_Member !== null ?  " | " + win.Team_Member.join(", ") : "")}</div>
          <div>{win.Details}</div>
        </div>
      </div>
    </Card>
  )
}

export default Win